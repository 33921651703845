import { render, staticRenderFns } from "./updateSubSystem.vue?vue&type=template&id=32828e42&scoped=true&"
import script from "./updateSubSystem.vue?vue&type=script&lang=js&"
export * from "./updateSubSystem.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "32828e42",
  null
  
)

export default component.exports